<template>
  <div class="title" v-html="content">
    
  </div>
</template>

<script>
export default {
    data(){
        return{
            content:`
            本指引是长城文创珍宝中心公众号开发者“云顶中城文化科技有限公司”（以下简称“开发者”）为处理你的个人信息而制定。

开发者处理的信息

· 为了展示该用户及他的作品的荣誉，开发者将在获取你的明示同意后，使用你的相册（仅写入）权限。

· 为了完善用户的个人信息，开发者将在获取你的明示同意后，收集你的微信昵称、头像。

· 为了方便在作品有问题时联系到用户，开发者将在获取你的明示同意后，收集你的手机号。

· 开发者收集你选中的照片或视频信息，用于完善并展示用户的作品信息。

· 开发者读取你的剪切板，用于获取作品编号。

你的权益

关于使用你的相册（仅写入）权限，你可以通过以下路径：公众号主页右上角“…”—“设置”—点击特定信息—点击“不允许”，撤回对开发者的授权。

关于收集你选中的照片或视频信息、读取你的剪切板，你可以通过以下路径：公众号主页右上角“...” — “设置” — “公众号已获取的信息” — 点击特定信息 — 点击“通知开发者删除”，开发者承诺收到通知后将删除信息。

关于你的个人信息，你可以通过以下方式与开发者联系，行使查阅、复制、更正、删除等法定权利。

- qq号: 3267884270

开发者对信息的存储

开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。

信息的使用规则

开发者将会在本指引所明示的用途内使用收集的信息

如开发者使用你的信息超出本指引目的或合理范围，开发者必须在变更使用目的或范围前，再次以弹窗方式告知并征得你的明示同意。

信息对外提供

开发者承诺，不会主动共享或转让你的信息至任何第三方，如存在确需共享或转让时，开发者应当直接征得或确认第三方征得你的单独同意。

开发者承诺，不会对外公开披露你的信息，如必须公开披露时，开发者应当向你告知公开披露的目的、披露信息的类型及可能涉及的信息，并征得你的单独同意。

若你认为开发者未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与开发者联系；或者向微信进行投诉。

- qq号: 3267884270

更新日期：2023年10月18日
生效日期：2023年10月18日`
        }
    },
    mounted(){
        let newlist = ''
        let list = this.content.split('\n')

        for(let i = 0;i<list.length;i++){
            console.log(list[i],list[i] == "")
            if(list[i] == "" || !list[i] ){
                continue;
            }
            
            let newitem = '<p>'+list[i]+'</p>'
            newlist = newlist + newitem
        }
      
        this.content = newlist
    }
}
</script>

<style scoped>
.title{
    text-indent:2em;
    font-size: 0.15rem;
    color: #333;
    padding: 0.25rem 0.15rem;
    box-sizing: border-box;
}

</style>